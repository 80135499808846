<template>
  <div>
    <span class="chart-header">
      <h3>{{title}}</h3>
      <p>{{subtitle}}</p>
    </span>
    <doughnut-chart :chart-data="datacollection" v-if="datacollection !== null"></doughnut-chart>
  </div>
</template>

<script>
  import DoughnutChart from './DoughnutChart.js'

  export default {
    components: {
      DoughnutChart
    },
    data () {
      return {
      }
    },
    props: {
      datacollection: Object,
      title: String,
      subtitle: String,
    }
  }
</script>

<style scoped>
.chart-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1em;
}
</style>