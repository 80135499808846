<template>
    <Graficos />
</template>

<script>
import Graficos from '@/components/Implantacao/Graficos'
export default {
  components: {Graficos},
  data(){
    return{}
  }
}
</script>

<style>

</style>