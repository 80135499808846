<template>
  <v-container>
    <div class="header">
      <span>
        <h3>Graficos Implantação #001</h3>
        <p style="text-align: left">Analise com atenção.</p>
      </span>
      <div class="grow"/>
      <button type="button" class="btn-voltar" @click="$router.go(-1)">voltar</button>
    </div>
    <div class="container-graficos">
      <div class="wrapper-grafico">
        <ChartBar 
          :datacollection="linedata1" 
          title="Title"
          subtitle="Subtitle"
          />
      </div>
      <div class="wrapper-grafico">
        <ChartDoughnut 
          :datacollection="linedata2" 
          title="Title"
          subtitle="Subtitle"
          />
      </div>
      <div class="wrapper-grafico">
        <ChartLine 
          :datacollection="linedata2" 
          title="Title"
          subtitle="Subtitle"
          />
      </div>
      <div class="wrapper-grafico">
        <ChartDoughnut 
          :datacollection="linedata1" 
          title="Title"
          subtitle="Subtitle"
          />
      </div>
    </div>
  </v-container>
</template>

<script>
import ChartLine from '@/components/Charts/ChartLine.vue'
import ChartDoughnut from '@/components/Charts/ChartDoughnut.vue'
import ChartBar from '@/components/Charts/ChartBar.vue'
// import ImplantacaoServico from '@/services/implantacao.service.js'
export default {
  components: {ChartLine, ChartBar, ChartDoughnut},
  data(){
    return {
      linedata1: null,
      linedata2: null,
    }
  },
  methods: {
    dataone() {
        this.linedata1 = {
          labels: [this.getRandomInt(), this.getRandomInt()],
          title: 'chart',
          datasets: [
            {
              label: 'Data One',
              backgroundColor: '#f87979',
              data: [this.getRandomInt(), this.getRandomInt()]
            }, {
              label: 'Data Two',
              backgroundColor: '#FC986F',
              data: [this.getRandomInt(), this.getRandomInt()]
            }
          ]
        }
    },
    datatwo() {
        this.linedata2 = {
          labels: [this.getRandomInt(), this.getRandomInt()],
          datasets: [
            {
              label: 'Data One',
              backgroundColor: '#E063A2',
              data: [this.getRandomInt(), this.getRandomInt()]
            }, {
              label: 'Data One',
              backgroundColor: '#FC6FFC',
              data: [this.getRandomInt(), this.getRandomInt()]
            }
          ]
        }
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    }
  },
  mounted(){
    this.dataone()
    this.datatwo()
  }
}
</script>

<style lang="scss" scoped>
.header{
  display: flex;
  padding: 1em;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
   background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.container-graficos{
  display: flex;
  flex-wrap: wrap;
  width: 70vw;
  margin: 0 auto;
  justify-content: space-around;
  @media screen and (max-width:768px) {
    width: 90vw;    
  }
  padding: 1em 0;
}


.btn-voltar{
    padding: .75em 1em;
    line-height: 1.5;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #1377f2;
    border-radius: 10px;
    text-transform: capitalize;
  }

.wrapper-grafico{
  padding: 1em;
  box-shadow:  0 10px 30px #d9d9d9,
                0 -10px 30px #ffffff;
  border-radius: 30px;
}

</style>